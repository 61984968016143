.image {
    transition: transform .3s; /* Animation */
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    min-width: 400px;
    min-height: 200px;
    max-width: 800px;
    max-height: 600px;
}

.image_img {
    min-width: 400px;
    min-height: 200px;
    max-width: 800px;
    max-height: 600px;
    width: 100%;
    height: 100%;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.image__overlay:hover {
    opacity: 1;
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.image__title {
    font-size: 2em;
    font-weight: bold;
}

.image__description {
    font-size: 1.25em;
    margin-top: 0.25em;
}

.image:hover {
    transform: scale(1.1); /* (150% zoom)*/
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@media screen and (min-width: 601px) {
    div.example {
        font-size: 80px;
    }
}

@media screen and (max-width: 600px) {
    div.example {
        font-size: 30px;
    }
}

.MuiCardMedia-img {
    height: '500px'
}